import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                  
                  
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/154/#" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup> Koncentrované sérum kyseliny hyaluronové 
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct singleproduct-new">
                <div className="prodimgcont prod-img">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/NTG-HB-Serum-1000x1000px2.png"
                      alt="NEUTROGENA® Hydro Boost® Koncentrované sérum kyseliny hyaluronové"
                    />
                  </div>
                </div>
                <div className="prod-info info new-product">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>®</sup><br/>Hydro Boost<sup>®</sup><br/>Koncentrované sérum kyseliny hyaluronové 
                    </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p>
                      Stimuluje produkci vlastní kyseliny hyaluronové v pokožce* a intenzivně hydratuje pro hladší pleť za pouhé 2 týdny.** 
                      </p>
                      <br/>
                      <p>
                      Obsahuje nejvyšší koncentraci kyseliny hyaluronové z řady Hydro Boost®, 100% přírodní trehalózu a provitamín B5. Okamžitě poskytuje intenzivní hydrataci a vyplňuje pokožku zevnitř. 
                      </p>  
                      <br/>
                      <p>
                      Lehká textura pomáhá posílit ochrannou kožní bariéru.
                      </p> 
                      <br/>
                      <p>
                      Dvě formy kyseliny hyaluronové (s nízkou a vysokou molekulovou hmotností) okamžitě poskytují intenzivní hydrataci. 
                      </p> 
                      <br/>
                      <p>
                      *Testováno laboratorně.**Vědecká studie s 35 účastníky
                      </p> 
                      <br/>
                      <p>
                      Bez parfemace. Vhodné pro všechny typy pleti včetně citlivé pokožky. Vyvinuto s dermatology. 
                      </p>                 
                    </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                      <p>
                      Aplikujte denně 3 - 4 kapky na vyčištěnou pokožku obličeje a krku. Jako další krok vaší hydratační péče použijte Hydro Boost® Hydratační pleťový gel.
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>UPOZORNĚNÍ:</dt>
                    <dd>
                      <p>Zabraňte kontaktu s očima.</p>
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>15 ml</dd>
                  </dl>
                </div>
                <div id="fb_35"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
